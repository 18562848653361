window.site = (function(){
  var settings = {};

  var init = function(options){
    $.extend(true, settings, options);
    
    events();
  }

  var events = function(){
    $(document)
  }

  return {
    init: init
  }
})();