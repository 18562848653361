require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')
require('jquery-validation')
require('jquery-validation/dist/localization/messages_pt_BR')
require('inputmask')
require('@fortawesome/fontawesome-free/js/all')

require('site/site')
require('site/home')

import 'stylesheets/site'

require.context('../images', true)