var Inputmask = require('inputmask').default;

window.home = (function(){
  var init = function(options){    
    validateForm();
    events();

    initInputmask();
    checkState();
  }

  var events = function(){
    $(document)
      .on('change', '#user_state_id', changeState)
  }

  var validateForm = function(){
    $('#new_user').validate({
      errorClass: 'has-text-danger',
      errorElement: 'div',
    });
  }

  var initInputmask = function(){
    input = document.getElementById('user_phone');

    Inputmask('(99) 9999[9]-9999').mask(input);
  }

  var changeState = function(){
    $.get('/cities?state_id=' + $('#user_state_id').val(), function(response){
      var cityInput = $('#user_city_id');
      cityInput.find('option').remove().end().append('<option></option>');

      for(cityId in response.cities){
        var city = response.cities[cityId];
        
        cityInput.append('<option value="' + city.id + '">' + city.name + '</option>')
      }
    });
  }

  var checkState = function(){
    if($('#user_state_id').val()){
      changeState();
    }
  }

  return {
    init: init
  }
})();